import { Box } from "@secuis/ccp-react-components";

import { useSearch } from "./Search.hooks";
import { SearchStyled } from "./Search.styles";

interface Props {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

export const Search = ({ value, onChange, placeholder }: Props) => {
    const { handleChange, handleClear } = useSearch({
        onChange,
    });

    return (
        <Box mb="S" data-testid="settings-csl-search">
            <SearchStyled value={value} onChange={handleChange} onClear={handleClear} placeholder={placeholder} />
        </Box>
    );
};

Search.displayName = "Search";
