import { ChangeEvent, KeyboardEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import TrackingActions from "src/store/tracking/TrackingActions";

interface UseSearchProps {
    onChange: (value: string) => void;
}

export const useSearch = ({ onChange }: UseSearchProps) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [hasStartedTyping, setHasStartedTyping] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event?.target) return;

        onChange(event.target.value);
        setSearchValue(event.target.value);
    };

    const clearSearchResults = () => {
        setHasStartedTyping(false);
        onChange("");

        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchClear));
    };

    const handleClear = () => {
        clearSearchResults();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (!hasStartedTyping) {
            setHasStartedTyping(true);
            dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchStartTyping, { Value: event.key }));
        }

        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsSearchSubmit, { Value: searchValue }));
    };

    return {
        handleChange,
        handleClear,
        handleKeyDown,
    };
};
