import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSeverityLevelTranslationKey } from "src/helpers/SeverityLevelHelper";
import { useIsUserClientGuardingAdmin } from "src/store/clientSwitcher/ClientSwitcherHooks";
import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";
import { IReportCategoryLevel1 } from "src/store/reportCategories/types";
import { RequestStatus } from "src/store/RequestStatus";

import { CATEGORY_ORDER } from "./ClientSeverityLevel.constants";
import { ClientSeverityTableData, SeverityLevel } from "./ClientSeverityLevel.types";

export const useClientSeverityLevel = () => {
    const { t } = useTranslation();
    const { reportCategories, fetchStatus } = useReportCategories();
    const [searchValue, setSearchValue] = useState("");
    const [allCategories, setAllCategories] = useState<ClientSeverityTableData>([]);
    const [searchResults, setSearchResults] = useState<ClientSeverityTableData>([]);
    const isEditModeEnabled = false; // Edit Mode is not available in first release

    const mapSeverityLevel = (severity: string): SeverityLevel => {
        if (severity in SeverityLevel) {
            return SeverityLevel[severity as keyof typeof SeverityLevel];
        }
    };

    const sortReportCategories = (categories: IReportCategoryLevel1[]): IReportCategoryLevel1[] =>
        [...categories].sort((a, b) => {
            const indexA = CATEGORY_ORDER.indexOf(a.key);
            const indexB = CATEGORY_ORDER.indexOf(b.key);

            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
        });

    const parseReportCategories = (data: IReportCategoryLevel1[]): ClientSeverityTableData => {
        return data.flatMap((level1Category) =>
            level1Category.level2Categories.flatMap((level2Category) =>
                level2Category.level3Categories.map((level3Category) => {
                    const severity = mapSeverityLevel(level3Category.severityLevel);

                    return {
                        name: `${t(`incident.category.${level1Category.key}`)} . ${t(`incident.category.${level2Category.key}`)} . ${t(
                            `incident.category.${level3Category.key}`,
                        )}`,
                        category: {
                            level1: level1Category.key,
                            level2: level2Category.key,
                            level3: level3Category.key,
                        },
                        severity: severity,
                        defaultSeverity: severity,
                    };
                }),
            ),
        );
    };

    const parsedCategories = useMemo(
        () => (reportCategories.length > 0 ? parseReportCategories(sortReportCategories(reportCategories)) : []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reportCategories],
    );

    useEffect(() => {
        setAllCategories(parsedCategories);
        setSearchResults(parsedCategories);
    }, [parsedCategories]);

    useEffect(() => {
        const results = searchValue.length
            ? allCategories.filter((row) => {
                  return `${row.name} ${getSeverityLevelTranslationKey(row.severity)}`.toLowerCase().includes(searchValue.toLowerCase());
              })
            : allCategories;

        setSearchResults(results);
    }, [allCategories, searchValue]);

    const isUserClientGuardingAdmin = useIsUserClientGuardingAdmin();

    return {
        tableData: searchResults,
        isLoading: fetchStatus === RequestStatus.loading,
        isEditable: isUserClientGuardingAdmin && isEditModeEnabled,
        onSeverityChange: () => {}, // TODO: Implement
        searchValue,
        setSearchValue,
    };
};
