import { Breakpoints, Separator, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Loading } from "src/components/shared/Loading";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import { useIsUserClientGuardingAdmin } from "src/store/clientSwitcher/ClientSwitcherHooks";
import TrackingActions from "src/store/tracking/TrackingActions";

import { useClientSeverityLevel } from "./ClientSeverityLevel.hooks";
import { ColumnsWrapperStyled, WrapperStyled } from "./ClientSeverityLevel.styles";
import { CategoriesCount, ClientSeverityTable, DescriptionSection, PageHeadline, ScreenSizeNotSupported, Search, SeverityLevelInfo } from "./components";

export const ClientSeverityLevel = () => {
    const { t } = useTranslation();
    const { tableData, isEditable, onSeverityChange, isLoading, searchValue, setSearchValue } = useClientSeverityLevel();
    const showTwoColumns = !useHasMaxWidth(Breakpoints.L);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isUserClientGuardingAdmin = useIsUserClientGuardingAdmin();
    const isClientSeverityLevelEditModeEnabled = useFeatureFlag(featureFlags.clientSeverityLevelEditMode);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsTabView));
    }, [dispatch]);

    return (
        <WrapperStyled>
            <Separator />
            <PageHeadline />
            {isMobile ? (
                <ScreenSizeNotSupported />
            ) : (
                <>
                    <Search value={searchValue} onChange={setSearchValue} placeholder={t("settings.clientSeverityLevel.Search.Placeholder")} />
                    {isClientSeverityLevelEditModeEnabled && isUserClientGuardingAdmin && <DescriptionSection />}
                    <CategoriesCount count={tableData.length} />
                    <ColumnsWrapperStyled showTwoColumns={showTwoColumns}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <ClientSeverityTable data={tableData} isEditable={isEditable} searchValue={searchValue} onSeverityChange={onSeverityChange} />
                        )}
                        <SeverityLevelInfo />
                    </ColumnsWrapperStyled>
                </>
            )}
        </WrapperStyled>
    );
};
